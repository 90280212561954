















import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'

@Component
export default class AwaitingActivation extends Vue {
  get userIsActive () {
    return this.$store.getters.getUserObj.activated
  }

  mounted () {
    if (this.userIsActive) {
      router.push('/')
    }
  }
}
