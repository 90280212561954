












































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'

@Component
export default class Account extends Vue {
  userDetails: any = {
    firstName: '',
    secondName: '',
    email: '',
    activated: false,
    key: '',
    newPassword: ''
  }

  confirmPassword: string = ''

  get user () {
    return this.$store.getters.getUserObj
  }

  validateNewPassword () {
    const _password = this.userDetails.newPassword as string
    if (_password.length < 6) {
      return 'Password must be 6 characters in length'
    }
    if (_password.length > 30) {
      return 'Password too long'
    }
    if (_password !== this.confirmPassword) {
      return 'Passwords must match'
    }
    return ''
  }

  get canSave () {
    if (this.user.traditionalAuth && this.userDetails.newPassword && this.userDetails.newPassword.length && this.validateNewPassword().length) {
      return false
    }
    return true
  }

  mounted () {
    this.resetUserDetails()
  }

  resetUserDetails () {
    this.userDetails = {
      key: this.user.key,
      firstName: this.user.firstName,
      secondName: this.user.secondName,
      email: this.user.email,
      activated: this.user.activated,
      newPassword: ''
    }
  }

  save () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to save these details?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$store.dispatch('saveUser', this.userDetails).then(() => {
          this.$store.dispatch('fetchNewAccessToken').then((accessToken: any) => {
            // handler for if api fails entirely:
            if (accessToken === null) {
              // console.log('accessToken === null')
              this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
              return
            }
            this.$bvModal.msgBoxOk('Details saved successfully.', {
              title: 'Success',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              centered: true
            })
          }).catch(() => {
            this.$bvModal.msgBoxOk('Session expired - You will now be redirected to log in.', {
              size: 'sm',
              buttonSize: 'sm',
              okTitle: 'OK',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(async value => {
              if (this.$store.getters.userIsNonSSO) {
                return this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
              }
              router.push({ name: 'sso' })
            })
          })
        }).catch(() => {
          this.resetUserDetails()
        })
      })
  }
}
